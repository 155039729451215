<template>
  <main class="row container_order">
    <div class="col-sm-12 p-0 page_title">
      <h1 class="o_ttl">{{ $t('ORDER_HISTORY.order') }}</h1>
      <p class="o_account d-flex justify-center">
        <span>
          <span>My account</span>
        </span>
        <span class="pl-1">
          <v-icon size="12" class="mr-1">mdi-chevron-right</v-icon>
          <span>My Orders</span>
        </span>
        <span class="pl-1">
          <v-icon size="12" class="mr-1">mdi-chevron-right</v-icon>
          <span>Order</span>
        </span>
      </p>
    </div>

    <div class="col-sm-12 order_info pb-6">
      <div class="row order_info--header mb-7">
        <div class="col-sm-6 o_details">
          {{ $t('PRODUCT_DESCRIPTION.order_details') }}
        </div>
      </div>
      <div v-if="isLastStep">
        <p>{{ currentStatus.title }}</p>
      </div>
      <div v-else class="row order_range d-block">
        <div class="col-sm-12 range_block pa-0 px-3">
          <ul class="range_bottom">
            <li
                class="range_item"
                v-for="(status, i) in statusList"
                :key="status.id"
            >
              <div
                  :class="[i <= moveSliderByIndex() ? 'range_line':'', 'range_line1']"
              />
              <div class="range_item__bottom-block">
                <span class="range_c1">{{ i + 1 }}</span>
                <p class="range_doc">{{ status.title }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row order_poduct_i">
        <div class="col-sm-3 o_line">
          <p class="poduct_i_text">{{ $t('ORDER_HISTORY.order_number') }}</p>
          <span class="info_text">{{  data.id }}</span>
        </div>
        <div class="col-sm-3 o_line">
          <p class="poduct_i_text">{{ $t('ORDER_HISTORY.date') }}:</p>
          <span class="info_text">
            {{ data.created_at }}
          </span>
        </div>
        <div class="col-sm-3 o_line">
          <p class="poduct_i_text">E-mail:</p>
          <span class="info_text grey--text">
            {{ data.email }}
          </span>
        </div>
        <div class="col-sm-3">
          <p class="poduct_i_text">{{ $t('ORDER_HISTORY.total') }}:</p>
          <span class="info_text">
            {{ data.price }} KGS
          </span>
        </div>
      </div>

      <div class="row info_about_o mb-6">
        <h2 class="ma-0 text-uppercase">
          {{ $t('ORDER_HISTORY.order_info') }}
        </h2>
        <div class="col-sm-12 pa-0 px-lg-12 px-sm-6">
          <v-expansion-panels flat class="product mb-1">
            <v-expansion-panel style="border-radius: 0">
              <v-expansion-panel-header class="pa-0">
                <span class="about_product">
                  {{ $t('REGISTRATION.product') }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <main
                    class="container_order container pa-0"
                    style="width: unset !important"
                >
                  <ul>
                    <v-row class="cart__table-header ma-0">
                      <v-col cols="5" class="py-0">Description</v-col>
                      <v-col cols="1" class="py-0">Price</v-col>
                      <v-col style="text-align: center" class="py-0">Quantity</v-col>
                      <v-col cols="2" class="py-0">Item Total</v-col>
                    </v-row>
                    <v-row
                        class="cart__table-item ma-0"
                        v-for="item in products"
                        :key="item.id"
                    >
                      <v-col cols="5" class="cart__table-item__desc py-0">
                        <div class="cursor-pointer">
                          <img
                              :src="baseUrl + item.product.photo"
                              :alt="getImageName(item.product.photo)" :title="getImageName(item.product.photo)" 

                              width="87"
                              height="77"
                          />
                        </div>
                        <div class="d-flex flex-column cursor-pointer">
                          <h4>{{ item.product.name.ru }}</h4>
                        </div>
                      </v-col>
                      <v-col cols="1" class="cart__table-item__price py-0 notranslate">
                        <span>
                          {{ item.price }} KGS
                        </span>
                      </v-col>
                      <v-col cols="1" class="cart__table-item__price py-0 notranslate">
                        <span>
                          {{ item.quantity }}
                        </span>
                      </v-col>
                      <v-col cols="2" class="cart__table-item__price py-0 pl-0 pr-6 notranslate">
                        <span >
                          {{ item.price * item.quantity }} KGS
                        </span>
                      </v-col>
                    </v-row>
                    <v-row
                        class="cart__table-item ma-0"
                    >
                      <v-col cols="5" class="cart__table-item__desc py-0">
                        <div class="special-order-history__artisan-img">
                          <img
                              :src="`${baseUrl}/storage/` + data.artisan.photo"
                              :alt="getImageName(data.artisan.photo)" :title="getImageName(data.artisan.photo)" 

                          />
                        </div>
                        <div class="d-flex flex-column">
                          <h4>{{ data.artisan.full_name }}</h4>
                          <span>
                            {{ data.artisan.email }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </ul>
                </main>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="about_order">
            <div class="col-sm-12 px-2 order">
              <ul class="total_order pa-0 d-flex justify-space-between">
                <li class="sum_count">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4">
                      {{ $t('PRODUCT_DESCRIPTION.second_table.totalCount') }}:
                    </b>
                    <span class="btn_product_sum">
                      {{ products.length }}
                    </span>
                  </p>
                </li>
                <li class="sum_count">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4">
                      Amount
                    </b>
                    <span class="btn_product_sum">
                      {{ data.price }} KGS
                    </span>
                  </p>
                </li>
                <li class="sum_count">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4">
                      Paid
                    </b>
                    <span class="btn_product_sum">
                      {{ `${paid}%` }}
                    </span>
                  </p>
                </li>
                <li v-if="data.delivery_price" class="btn_item">
                  <p class="btn_total">
                    <b class="btn_product_text mr-4"
                    >{{ $t('PRODUCT_DESCRIPTION.second_table.delivery') }}:
                    </b>
                    <span class="btn_product_sum">
                      {{ data.delivery_price }} KGS
                    </span>
                  </p>
                </li>
                <li class="btn_item">
                  <p class="t_order_total btn_total">
                    <b class="btn_product_text mr-4">
                      {{ $t('PRODUCT_DESCRIPTION.second_table.total') }}:
                    </b>
                    <span class="btn_product_sum">
                      {{ data.price }} KGS
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div
              v-if="data.half_price_paid < data.half_price"
              class="about_total"
          >
            <span class="order_total">
              Pay half the cost
            </span>
            <span class="order_sum order_sum--total">
              <button
                  class="order__pay-btn"
                  @click="payHalf"
              >
                Pay
              </button>
            </span>
          </div>
          <div class="about_total">
            <span class="order_total">
              {{ $t('PRODUCT_DESCRIPTION.second_table.total') }}
            </span>
            <span class="order_sum order_sum--total">
              {{ data.price }} KGS
            </span>
          </div>
          <div class="about_total">
            <span class="order_total">
              Paid
            </span>
            <span class="order_sum order_sum--total d-flex order__residue">
              <span class="order__half-price">
                {{ data.paid_price }} KGS
              </span>
              <span>
                <span class="order__residue-title">Residue:</span> {{ data.price_left !== 0 ? data.price_left - (data.delivery_price || 0) : 0 }} KGS
              </span>
            </span>
          </div>
          <div
              v-if="data.delivery_price !== null"
              class="about_total"
          >
            <span class="order_total">
              Final account
            </span>
            <span class="order_sum order_sum--total d-flex order__residue">
              <span class="order__half-price">
                {{ data.half_price }} KGS
              </span>
              <span class="order__half-price">
                Delivery: {{ data.delivery_price }} KGS
              </span>
              <span class="order__half-price">
                <template v-if="!!data.price_left">
                  Total: {{ data.price_left }} KGS
                </template>
                <template v-else>
                  Everything was paid for
                </template>
              </span>

              <button
                  v-if="data.price_left"
                  class="order__pay-btn"
                  @click="pay"
              >
                Pay
              </button>
            </span>
          </div>
          <div
              v-if="certificatesOfProducts.length"
              class="about_total"
          >
            <span class="order_total">
              Certificate for Shyrdak
            </span>
            <div class="order_sum order_sum--total d-flex order__residue">
              <div
                  v-for="(passport, i) in certificatesOfProducts"
                  :key="i"
                  class="special-order-history__certificate-link-block"
              >
                <a
                    :href="passport"
                    target="_blank"
                    class="special-order-history__certificate-link"
                >
                  {{ `Certifcate ${i + 1}` }}
                </a>
              </div>
            </div>
          </div>
          <div class="order__address">
            <div class=""> </div>
            <div class=""> </div>
          </div>
        </div>

        <div class="col-sm-12 pa-0 px-lg-8 px-sm-10">
          <div class="row order__address ma-0 special-order-history__stamp-wrapper">
            <div class="col-sm-3 payment__terminal">
              <h3>{{ $t('ORDER_HISTORY.payment_terminal') }}</h3>
              <p>
                Покупатель: {{ data.full_name }}
              </p>
              <p>
                Email: {{ data.email }}
              </p>
            </div>
            <div class="col-sm-3 delivery__address">
              <h3>{{ $t('ORDER_HISTORY.delivery_address') }}:</h3>
              <p>
                Адрес: {{ data.address }}
              </p>
              <p>
                Страна: {{ data?.country?.country }}
              </p>
              <p>
                Почтовый индекс: {{ data.zip_code }}
              </p>
            </div>
            <div class="col-sm-2 pa-0"></div>
            <div
                v-if="+data.price_left === 0"
                class="special-order-history__stamp-block"
            >
              <img src="/img/paid-stamp.png" alt="paid stamp" title="paid stamp">
            </div>
          </div>
        </div>
      </div>

      <v-card>
        <table>
          <thead>
          <tr>
            <th>ID</th>
            <th>Order number</th>
            <th>Order amount</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in paymentHistory"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.order_number }}</td>
            <td>{{ item.sum }} KGS</td>
            <td>
              {{ item.status }}
              <template v-if="item.status.toLowerCase() === 'successfully'">
                <v-icon color="green">mdi-check-circle</v-icon>
              </template>
            </td>
            <td>{{ item.created_at }}</td>
          </tr>
          </tbody>
        </table>
      </v-card>

    </div>
  </main>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

export default {
  name: 'SpecialOrderHistoryDetail',
  data() {
    return {
      baseUrl: '',
      currentStatus: {},
      statusList: [
        {
          "id": 1,
          "title": "На проверке",
          "slug": "order_confirmation"
        },
        {
          "id": 2,
          "title": "Перевели ремесленнику",
          "slug": "transferred_to_craftsman"
        },
        {
          "id": 3,
          "title": "На производстве",
          "slug": "in_production"
        },
        {
          "id": 4,
          "title": "Подготовка шерсти",
          "slug": "wool_preparation"
        },
        {
          "id": 5,
          "title": "Производство войлока и пряжи",
          "slug": "felt_and_yarn_production"
        },
        {
          "id": 6,
          "title": "Крой и предварительная сборка",
          "slug": "cutting_and_pre-assembly"
        },
        {
          "id": 7,
          "title": "Стежка",
          "slug": "stitch"
        },
        {
          "id": 8,
          "title": "Подготовка к отправке",
          "slug": "preparing_for_shipping"
        },
        {
          "id": 9,
          "title": "Полная оплата",
          "slug": "fully_paid"
        },
        {
          "id": 10,
          "title": "Заказ отправлен",
          "slug": "order_sent"
        },
        {
          "id": 11,
          "title": "Доставлено",
          "slug": "delivered"
        }
      ],
      data: [],
      products: [],
      paymentHistory: [],
      certificatesOfProducts: []
    }
  },
  computed: {
    isLastStep () {
      return this.currentStatus.id === this.statusList.at(-1).id
    },
    // eslint-disable-next-line vue/return-in-computed-property
    paid () {
      if (this.data.half_price_paid === 0) {
        return 0
      }
      if (+this.data.price_left === 0) {
        return 100
      }
      if (this.data.half_price === this.data.half_price_paid) {
        return 50
      }
    }
  },
  methods: {
    getImageName,

    getPassportsForProduct () {
      axios.get(`/specialgoods/artisan/orders/${this.data.id}/passports`)
          .then((res) => {
            let passports = []

            res.data.data.forEach((item) => {
              passports.push(item.passport_url)
            })

            this.certificatesOfProducts = passports
          })
    },
    moveSliderByIndex() {
      let index = 0

      this.statusList.forEach((status, i) => {
        if (this.currentStatus.slug === status.slug) {
          index = i
        }
      })

      return index
    },
    getPaymentHistory () {
      axios.get(`/specialgoods/order/${this.data.id}/payments`)
      .then((res) => {
        this.paymentHistory = res.data.data
      })
    },
    payHalf () {
      axios.post(`/specialgoods/order/${this.data.order_uuid}/halfPriceCheckout`)
      .then((res) => {
        window.open(res.data.redirect_url, '_blank')
      })
    },
    pay () {
      axios.post(`/specialgoods/order/${this.data.order_uuid}/checkout`)
      .then((res) => {
        window.open(res.data.redirect_url, '_blank')
      })
    },
    getClientOrderById (id) {
      return axios.get(`/specialgoods/order/${id}`)
          .then((res) => {
            this.data = res.data.data
            this.currentStatus = res.data.data.status
          })
    },
    getOrderProducts (id) {
      axios.get(`/specialgoods/order/${id}/products`)
          .then((res) => {
            this.products = res.data.data
          })
    }
  },
  async mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
    this.getClientOrderById(this.$route.params.id)
        .then(() => {
          this.getPaymentHistory()
          this.getOrderProducts(this.data.id)
          this.getPassportsForProduct()
        })
  },
}
</script>

<style lang="scss" scoped>
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

th, td {
  padding: 5px 10px;
}
tr {
  border-bottom: 1px solid #ccc;
}
table {
  width: 100%;
  border-collapse: collapse;
}

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

%d_flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.order {
  &__residue {
    display: flex;
    flex-direction: column;

    &-title {
      color: red;
    }
  }
  &__half-price {
    text-align: right;
  }
  &__pay-btn {
    font-size: 15px;
    border: 1px solid #ccc;
    padding: 3px 10px;
  }
}
.product {
  .v-expansion-panel {
    button {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
  }
}

.special-order-history {
  &__stamp-wrapper {
    position: relative;
  }
  &__artisan-img {
    width: 87px;
    height: 77px;
    min-width: 87px;
    min-height: 77px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__stamp-block {
    position: absolute;
    right: 80px;
    top: -54px;
    width: 150px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  &__certificate-link {
    text-decoration: underline !important;

    &-block {
      display: flex;
      flex-direction: column;
    }
  }
}
.about_total {
  margin-top: 10px;
  background: white;
  padding: 8px 20px;
  @include ftz(1rem, 500, #666666);
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 15px !important;
  @media (max-width: 481px) {
    margin: 0;
  }
  .order_sum {
    font-family: 'Roboto Condensed';
    font-size: 20px !important;
    @include ftz(30px, 400, #666666);
    &--total {
      font-weight: 700;
    }
  }
  .order_price {
    @include ftz(0.875rem, 700, #666666);

    .order_sum {
      @include ftz(1.125rem, 400, #666666);
    }
  }

  .order_total {
    @include ftz(1.5rem, 700, #31343c);
    padding: 8px 10px;

    .order_sum {
      @include ftz(1.5rem, 700, #31343c);
    }
  }
}

.page_title {
  margin-left: 0;
  @media (max-width: 575px) {
    margin-left: 16px;
  }
}
.cart__table-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}
.container_order {
  margin: 0;
  padding: 20px;
  background: #f9f9f9;
  @media (max-width: 575px) {
    padding: 0px;
  }
}
.o_ttl {
  letter-spacing: 1px;
  @include ftz(1.5rem, 600, $black);
}

.o_account {
  @include ftz(12px, 400, $gr_col);
  position: relative;
  letter-spacing: 0.2px;
  padding-bottom: 12px;
}

.order_info {
  height: 100hv;
  background: $white;
  &--header {
    width: 96%;
    margin: 0 auto;
  }
  .o_details {
    @include ftz(1.125rem, 500, $bl);
    padding-left: 0;
    @media (max-width: 481px) {
      @include ftz(0.75rem, 500, $bl);
    }
    @media (max-width: 575px) {
      font-size: 22px;
    }
  }

  .view_details {
    cursor: pointer;
    @include ftz(18px, 500, $bl_c);
    padding-right: 0;
    @media (max-width: 481px) {
      @include ftz(0.75rem, 500, $bl_c);
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-right: 12px;
      // margin-left: 2rem;
    }
  }

  .order_range {
    border: 1px solid $gr;
    width: 96%;
    margin-right: 1rem;
    margin-left: 2rem;
    margin-top: 20px;
    @extend %d_flex;
    margin: 0 auto;

    @media (max-width: 481px) {
      width: 100%;
    }

    .range_block {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      position: relative;
      overflow-x: auto;
      margin: 10px auto;

      .range_ul_li {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        width: 1100px;
        margin-bottom: 5px;
        padding: 0;
        @media (max-width: 481px) {
          width: 979px;
        }

        .range_line {
          width: 30rem;
          height: 5px;
          background: $green_c;
          // margin-top: -32px;
          position: relative;
        }

        .range_line1 {
          width: 32rem;
          height: 5px;
          background: #e4e4e4 !important;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 2;
        }
      }

      .range_bottom {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 0;

        @media (max-width: 481px) {
          margin: 0;
          width: 980px;
        }

        .range_line {
          width: 100%;
          height: 5px;
          position: relative;

          &:before {
            content: '';
            width: 100%;
            height: 5px;
            background: $green_c;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 2;
          }
        }

        .range_line1 {
          width: 100%;
          height: 5px;
          background: #e4e4e4;
          position: relative;
        }

        .range_item {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          position: relative;

          &__bottom-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 100px;

            .range_doc {
              padding: 0 40px !important;
            }
          }

          .range_c1 {
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background: $green_c;
            border: 1px solid;
            color: #ffffff;
            display: flex;
            justify-content: space-around;

            &--disabled {
              background-color: #e4e4e4;
              color: #707070;
              border-color: #fff;
            }
          }

          .range_doc {
            margin-top: 1rem;
            @include ftz(0.875rem, 500, #31343c);

            @media (max-width: 481px) {
              margin-top: 1.2rem;
              @include ftz(0.65rem, 500, #31343c);
            }
          }
          .range_date {
            @include ftz(0.875rem, 500, #a4a4a4);
          }
        }
      }
    }
  }

  .order_poduct_i {
    margin: 2rem 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 5px;
    @media (max-width: 481px) {
      margin: 2rem 0rem;
      padding: 0;
    }

    .col-sm-3 {
      @media (min-width: 374px) {
        flex: 0 0 auto;
        width: 25%;
        padding: 0;
      }
    }

    .o_line {
      border-right: 1px solid #f3f1f1;
    }

    .poduct_i_text {
      @include ftz(1rem, 400, #31343c);
      text-transform: capitalize;

      @media (max-width: 481px) {
        @include ftz(0.7rem, 400, #31343c);
      }
    }

    .info_text {
      @include ftz(1.125rem, 700, #31343c);

      @media (max-width: 897px) {
        @include ftz(1rem, 700, #31343c);

        @media (max-width: 770px) {
          @include ftz(0.655rem, 700, #31343c);

          @media (max-width: 481px) {
            @include ftz(0.5rem, 700, #31343c);
          }
        }
      }
    }
  }
  .info_about_o,
  .repeat {
    width: 96%;
    margin: 0 auto;
    @media (max-width: 481px) {
      width: 100%;
      margin: auto;
    }
  }

  .info_about_o {
    background: #fbfbfb;

    h2 {
      @include ftz(1rem, 500, #666666);
      text-align: center;
      padding: 15px;
    }

    .about_order {
      background: white;
      padding: 8px 20px;
      @include ftz(1rem, 500, #666666);
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 15px !important;
      margin-top: 10px;
      @media (max-width: 481px) {
        margin: 0;
      }
      .order {
        // max-width: 960px;
        overflow-x: auto;
      }
      .subtotal {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }

      .order_sum {
        font-family: 'Roboto Condensed';
        @include ftz(1.125rem, 400, #666666);
        &--total {
          font-weight: 700;
        }
      }

      .order_price {
        @include ftz(0.875rem, 700, #666666);

        .order_sum {
          @include ftz(1.125rem, 400, #666666);
        }
      }

      .order_total {
        @include ftz(1.5rem, 700, #31343c);
        padding: 8px 10px;

        .order_sum {
          @include ftz(1.5rem, 700, #31343c);
        }
      }
    }

    .info_payment {
      margin-left: 3rem;
      padding-top: 10px;
      margin-right: 2rem;
      overflow: hidden;
      margin-bottom: 0.5rem;

      .payment_b {
        .name_payment {
          @include ftz(1rem, 500, #666666);
        }
        .text_payment {
          @include ftz(0.75rem, 400, #666666);
        }
      }
    }
  }
}
.container_order {
  background-color: #fff;
}
.cart__item {
  display: grid;
}
.cart__item-container {
  display: grid;
  border: 1px solid $gr;
  &--top {
    grid-template-columns: 1fr 1fr 4fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &--bottom {
    grid-template-columns: 2fr 4fr;
    border-top: none;
  }
}
.item-container {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }
  &__title {
    border-bottom: 1px solid $gr;
    background-color: $gr;
    text-transform: uppercase;
    @include ftz(16px, 400, $bl);
    text-align: center;
  }
  &__content {
    width: 100%;
    padding-top: 100%;
    & > div {
      position: absolute;
      inset: 0;
    }
    &-color {
      width: 50%;
      height: 50%;
    }
  }
  &__castomization-btn {
    height: 100%;
    border-right: 1px solid $gr;
  }
  &__castomization-logo {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}
.content {
  &__button {
    color: $grey;
    flex-grow: 1;
    cursor: pointer;
    &--increase {
      background-color: $green;
    }
    &--decrease {
      background-color: $gr;
    }
  }
}
.btn_item {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;
  @media (max-width: 575px) {
    font-size: 13px;
    font-weight: 700 !important;
    width: 130px;
    padding: 5px 20px;
  }
  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }
  .btn_product_text {
    font-weight: bold !important;
  }
}
.sum_count {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;
  @media (max-width: 575px) {
    font-size: 13px;
    font-weight: bold !important;
    width: 130px;
    padding: 5px 20px;
  }
  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }
  .btn_product_text {
    font-weight: bold !important;
  }
}
.btn_total {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px;
  width: 132px;
}
</style>
